var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "div",
        { class: _vm.isMobile() ? "card" : "card tw-w-3/5" },
        [
          _c(
            "PageHeader",
            {
              attrs: { tag: "h3" },
              scopedSlots: _vm._u([
                {
                  key: "right",
                  fn: function () {
                    return [
                      _c(
                        "v-btn",
                        {
                          attrs: { small: "", color: "secondary" },
                          on: { click: _vm.showAddTokenPopup },
                        },
                        [_vm._v("New")]
                      ),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [_vm._v(" API Tokens ")]
          ),
          _c("div", { staticClass: "table-wrapper" }, [
            _c("table", [
              _vm._m(0),
              _c(
                "tbody",
                _vm._l(_vm.tokens, function (token) {
                  return _c("tr", { key: token.id }, [
                    _c("td", [_vm._v(_vm._s(token.name))]),
                    _c("td", { staticClass: "actions" }, [
                      _c(
                        "a",
                        {
                          staticClass: "danger",
                          attrs: { href: "" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.confirmDeleteEntity(token)
                            },
                          },
                        },
                        [_vm._v("revoke")]
                      ),
                    ]),
                  ])
                }),
                0
              ),
            ]),
          ]),
          _c(
            "Modal",
            {
              ref: "tokenRevealModal",
              attrs: { closeOnCurtainClick: false, width: "800" },
              scopedSlots: _vm._u(
                [
                  _vm.canCopyToClipboard
                    ? {
                        key: "buttons",
                        fn: function () {
                          return [
                            _c(
                              "v-btn",
                              {
                                attrs: { color: "secondary" },
                                on: {
                                  click: function ($event) {
                                    return _vm.copyToClipboard(
                                      _vm.modalBearerToken
                                    )
                                  },
                                },
                              },
                              [_vm._v("Copy API token")]
                            ),
                          ]
                        },
                        proxy: true,
                      }
                    : null,
                ],
                null,
                true
              ),
            },
            [
              _c("h3", [_vm._v("API Token: " + _vm._s(_vm.modalTokenName))]),
              _c("p", { staticClass: "tw-text-red-400" }, [
                _vm._v(
                  " Copy this API token for your records. This is the last time you will be able to view it. "
                ),
              ]),
              _c("FormTextarea", {
                attrs: {
                  readonly: "",
                  rows: "13",
                  name: "apiToken",
                  textStyle: {
                    backgroundColor: "#FFFFFF",
                    outline: "0.5px solid black",
                    height: "auto",
                    maxHeight: "35vh",
                  },
                },
                model: {
                  value: _vm.modalBearerToken,
                  callback: function ($$v) {
                    _vm.modalBearerToken = $$v
                  },
                  expression: "modalBearerToken",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "600" },
          on: {
            keydown: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
              )
                return null
              return _vm.closeAddTokenPopup.apply(null, arguments)
            },
          },
          model: {
            value: _vm.showModal,
            callback: function ($$v) {
              _vm.showModal = $$v
            },
            expression: "showModal",
          },
        },
        [
          _c(
            "v-card",
            { staticClass: "p-1", attrs: { justify: "center" } },
            [
              _c(
                "v-card-title",
                [
                  _vm._v("API Token "),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { icon: "" },
                      on: { click: _vm.closeAddTokenPopup },
                    },
                    [_c("v-icon", [_vm._v("mdi-close")])],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-form",
                    {
                      ref: "form",
                      attrs: { "lazy-validation": "" },
                      on: {
                        submit: function ($event) {
                          $event.preventDefault()
                          return _vm.createToken.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _c("v-text-field", {
                        attrs: {
                          name: "name",
                          label: "Name your API token",
                          rules: [(v) => !!v || "API Token Name is required"],
                          outlined: "",
                          dense: "",
                        },
                        model: {
                          value: _vm.tokenName,
                          callback: function ($$v) {
                            _vm.tokenName = $$v
                          },
                          expression: "tokenName",
                        },
                      }),
                      _c(
                        "v-btn",
                        {
                          staticClass: "mt-2",
                          attrs: { color: "secondary", type: "submit" },
                        },
                        [_vm._v("Create")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("DeleteModal", {
        attrs: {
          deleteModal: _vm.deleteModal,
          entitySelectedForDeletion: _vm.entitySelectedForDeletion,
          config: _vm.config,
          modalAction: "revoke",
        },
        on: {
          deleteEntity: _vm.deleteEntity,
          closeDeleteModal: _vm.closeDeleteModal,
        },
      }),
      _c("LoadingModal", {
        attrs: {
          loadingText: "Creating API Token...",
          showModal: _vm.isSubmitting,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Name")]),
        _c("th", { staticClass: "actions" }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }