var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("v-text-field", {
    attrs: {
      value: _vm.value,
      type: _vm.inputType,
      id: _vm.inputId,
      label: _vm.label,
      placeholder: _vm.placeholder,
      outlined: _vm.outlined,
      dense: _vm.dense,
      rules: _vm.rules,
      "error-messages": _vm.errorMessages,
      autofocus: _vm.autofocus,
      required: _vm.required,
    },
    on: {
      input: function ($event) {
        return _vm.$emit("input", $event)
      },
      focus: function ($event) {
        return _vm.$emit("focus", $event)
      },
      keyup: function ($event) {
        return _vm.$emit("keyup", $event)
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }