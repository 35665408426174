var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("h1", { staticClass: "tw-text-center" }, [
    _vm._v("404 - Not Found"),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }