var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { class: _vm.headerClass },
    [
      _vm.subHeader
        ? [
            _c("div", { staticClass: "middle" }, [
              _c("h3", [_vm._t("default")], 2),
            ]),
          ]
        : [
            _c(
              "div",
              { staticClass: "left" },
              [_c(_vm.tag, { tag: "component" }, [_vm._t("default")], 2)],
              1
            ),
            _c("div", { staticClass: "middle" }, [_vm._t("middle")], 2),
          ],
      _c("div", { staticClass: "right" }, [_vm._t("right")], 2),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }