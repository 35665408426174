var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "card" },
    [
      _c("PageHeader", { attrs: { tag: "h3" } }, [_vm._v(" GPG Public Keys ")]),
      _c("icon", { attrs: { icon: "key" } }),
      _c("span", { staticClass: "tw-ml-4" }, [
        _c("span", [
          _c(
            "a",
            {
              attrs: {
                href: `${_vm.publicPath}keys/core-commerce.gpg`,
                download: "",
              },
            },
            [_vm._v("Download")]
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }