var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-app",
    { class: [_vm.context, { guest: !_vm.authenticated }] },
    [
      _vm.showBars ? _c("Header") : _vm._e(),
      _vm.showBars
        ? _c("Navbar", { on: { routerViewReload: _vm.reload } })
        : _vm._e(),
      _c("BrandTheme"),
      _c(
        "v-main",
        [
          _c(
            "v-container",
            { attrs: { fluid: "" } },
            [
              _vm.context
                ? _c("router-view", {
                    key: this.$route.fullPath + _vm.linkIndex,
                  })
                : _c("Spinner", { attrs: { fullscreen: "" } }),
              _vm.isIdle ? _c("IdleModal") : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _vm.showBars ? _c("Footer") : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }