var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    [
      _c(
        "v-card",
        {
          staticClass:
            "mx-auto d-flex justify-center flex-column align-center ma-16",
          attrs: {
            "max-width": "350",
            outline: "",
            elevation: "2",
            "min-height": "350",
          },
        },
        [
          _c("v-card-title", { staticClass: "justify-center" }, [
            _vm._v("Support"),
          ]),
          _c(
            "PageLoader",
            { ref: "supportLoader" },
            [
              _c("v-card-text", { staticClass: "pt-16" }, [
                _c("p", [_vm._v("For support, please contact us!")]),
                _c("p", [
                  _vm._v(" Email: "),
                  _c(
                    "a",
                    {
                      staticClass: "link-text",
                      attrs: { href: `mailto:${_vm.supportInfo.email}` },
                    },
                    [_vm._v(_vm._s(_vm.supportInfo.email))]
                  ),
                ]),
                !_vm.isMerchantContext ||
                (_vm.isMerchantContext && _vm.showPhone)
                  ? _c("p", [
                      _vm._v(" Phone: "),
                      _c(
                        "a",
                        {
                          staticClass: "link-text",
                          attrs: { href: `tel:${_vm.supportInfo.phone}` },
                        },
                        [_vm._v(" " + _vm._s(_vm.supportInfo.phone) + " ")]
                      ),
                    ])
                  : _vm._e(),
                _vm.isMerchantContext && _vm.supportHtml
                  ? _c("div", {
                      domProps: { innerHTML: _vm._s(_vm.supportHtml) },
                    })
                  : _vm._e(),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm.isIsoContext || _vm.isMerchantContext
        ? _c(
            "v-card",
            {
              staticClass:
                "mx-auto d-flex justify-center flex-column align-center ma-16",
              attrs: {
                "max-width": "350",
                outline: "",
                elevation: "2",
                "min-height": "350",
              },
            },
            [
              _c("v-card-title", { staticClass: "justify-center" }, [
                _vm._v(" Agreements "),
              ]),
              _c(
                "PageLoader",
                { ref: "documentLoader" },
                [
                  _vm.signedAgreement.name &&
                  _vm.signedAgreement.status === "uploaded"
                    ? _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    color: "secondary",
                                    href: `${_vm.signedAgreement.url}`,
                                    target: "_blank",
                                  },
                                },
                                [
                                  _vm._v(
                                    " Download " +
                                      _vm._s(_vm.signedAgreement.name) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.isMerchantContext && !_vm.hidePricingSchedule
                    ? _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { staticClass: "justify-center d-flex" },
                            [
                              _c(
                                "v-btn",
                                { on: { click: _vm.showPricingSchedule } },
                                [_vm._v(" Pricing Schedule ")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c("DocumentViewer", {
        ref: "documentViewer",
        attrs: { html: _vm.docHtml, supportPage: "" },
        on: { closeRatesModal: _vm.closeRatesModal },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }