var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c(
        "div",
        { staticClass: "form-wrapper" },
        [
          _c("logo"),
          _c(
            "form",
            {
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.attemptReset.apply(null, arguments)
                },
              },
            },
            [
              _vm.isSuccess
                ? _c("div", { staticClass: "alert alert-success" }, [
                    _vm._v(" " + _vm._s(_vm.successMessage) + " "),
                  ])
                : _vm._e(),
              _c("v-text-field", {
                attrs: {
                  type: "password",
                  name: "password",
                  label: "Password",
                  "error-messages": _vm.validationErrors.password,
                  outlined: "",
                  dense: "",
                },
                model: {
                  value: _vm.password,
                  callback: function ($$v) {
                    _vm.password = $$v
                  },
                  expression: "password",
                },
              }),
              _c("v-text-field", {
                attrs: {
                  type: "password",
                  name: "confirm",
                  label: "Confirm password",
                  "error-messages": _vm.validationErrors.confirm,
                  outlined: "",
                  dense: "",
                },
                model: {
                  value: _vm.confirm,
                  callback: function ($$v) {
                    _vm.confirm = $$v
                  },
                  expression: "confirm",
                },
              }),
              _c(
                "div",
                { staticClass: "buttons" },
                [
                  _c(
                    "v-btn",
                    { attrs: { color: "secondary", type: "submit" } },
                    [_vm._v("Set password")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("LoadingModal", {
        attrs: {
          loadingText: "Updating Password...",
          showModal: _vm.isSubmitting,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }