var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "input-wrapper",
      class: { "input-wrapper-inline": _vm.inline },
    },
    [
      _vm.hasLabel
        ? _c("label", { attrs: { for: _vm.name } }, [_vm._v(_vm._s(_vm.label))])
        : _vm._e(),
      _c("div", { staticClass: "input-group relative" }, [
        _c("input", {
          directives: [
            {
              name: "mask",
              rawName: "v-mask",
              value: _vm.mask,
              expression: "mask",
            },
          ],
          class: {
            "appearance-none": !_vm.step,
            "input-padding-right": _vm.addPaddingRight,
          },
          style: { backgroundColor: "#FFFFFF", outline: "0.5px solid black" },
          attrs: {
            type: _vm.type,
            id: _vm.inputId,
            name: _vm.name,
            min: _vm.min,
            max: _vm.max,
            step: _vm.step,
            autofocus: _vm.autofocus,
            placeholder: _vm.placeholder,
            pattern: _vm.pattern,
            title: _vm.errorMessage,
            required: _vm.required,
            readonly: _vm.readonly,
            autocomplete: _vm.autocomplete,
            disabled: _vm.disabled,
          },
          domProps: { value: _vm.value },
          on: {
            input: function ($event) {
              return _vm.$emit("input", $event.target.value)
            },
            focus: function ($event) {
              return _vm.$emit("focus", $event)
            },
            keyup: function ($event) {
              return _vm.$emit("keyup", $event)
            },
          },
        }),
        _vm.hasInputAppend
          ? _c(
              "div",
              { staticClass: "append", class: { tight: _vm.tightAppend } },
              [_vm._t("append")],
              2
            )
          : _vm._e(),
        _vm.hasInputList
          ? _c("div", { staticClass: "input-list" }, [_vm._t("inputList")], 2)
          : _vm._e(),
      ]),
      _vm.errors && _vm.validationKey in _vm.errors
        ? _c(
            "ul",
            { staticClass: "errors" },
            _vm._l(_vm.errors[_vm.validationKey], function (error, i) {
              return _c("li", { key: i }, [_vm._v(_vm._s(error))])
            }),
            0
          )
        : _vm._e(),
      _c("ul", { staticClass: "errors" }, [_vm._t("clientErrors")], 2),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }