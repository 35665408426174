var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "tw-flex tw-flex-row" }, [
    _c(
      "div",
      { staticClass: "tw-px-4 tw-border-gray-400" },
      [_c("h1", [_vm._v("Security Keys")]), _c("GpgPublicKeys")],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }