var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _vm.loading
        ? [
            _vm._t("loading", function () {
              return [_c("Spinner")]
            }),
          ]
        : _vm._e(),
      _vm.ready ? [_vm._t("default")] : _vm._e(),
      _vm.error
        ? [
            _c(
              "div",
              { staticClass: "alert alert-error" },
              [
                _vm._t("error", function () {
                  return [_vm._v("Something went wrong. Please try again.")]
                }),
              ],
              2
            ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }