var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "container" }, [
    _c(
      "div",
      { staticClass: "form-wrapper" },
      [
        _c("Logo"),
        _c(
          "v-form",
          {
            staticClass: "rounded-lg",
            on: {
              submit: function ($event) {
                $event.preventDefault()
                return _vm.attemptLogin.apply(null, arguments)
              },
            },
          },
          [
            _c("h2", { staticStyle: { "text-align": "center" } }, [
              _vm._v("Login"),
            ]),
            _c(
              "PageLoader",
              { ref: "loader" },
              [
                _c("TextInput", {
                  attrs: {
                    name: "username",
                    label: "User name",
                    placeholder: "User name",
                    errors: _vm.validationErrors,
                    autofocus: true,
                    required: "",
                    dense: "",
                  },
                  model: {
                    value: _vm.username,
                    callback: function ($$v) {
                      _vm.username = $$v
                    },
                    expression: "username",
                  },
                }),
                _c("TextInput", {
                  attrs: {
                    dense: "",
                    name: "password",
                    label: "Password",
                    placeholder: "Password",
                    errors: _vm.validationErrors,
                    inputType: _vm.InputTypes.PASSWORD,
                  },
                  model: {
                    value: _vm.password,
                    callback: function ($$v) {
                      _vm.password = $$v
                    },
                    expression: "password",
                  },
                }),
                _c(
                  "v-card-actions",
                  [
                    _c(
                      "v-col",
                      { staticClass: "text-left pa-0" },
                      [
                        _c(
                          "router-link",
                          { attrs: { to: { name: `password-reset` } } },
                          [_vm._v(" Forgot Password? ")]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { staticClass: "text-right pa-0" },
                      [
                        _c(
                          "v-btn",
                          {
                            staticClass: "mr-4",
                            attrs: {
                              right: "",
                              color: "success",
                              type: "submit",
                            },
                          },
                          [_vm._v(" Login ")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }