var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("v-data-table", {
        staticClass: "elevation-1",
        attrs: {
          dense: "",
          height: "30vh",
          headers: _vm.headers,
          items: _vm.webhooks,
          options: _vm.options,
          "multi-sort": true,
          "server-items-length": _vm.meta.total,
          "header-props": {
            sortIcon: "mdi-arrow-down",
          },
          loading: _vm.loading,
        },
        on: {
          "update:options": function ($event) {
            _vm.options = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "top",
            fn: function () {
              return [
                _c(
                  "v-toolbar",
                  { attrs: { flat: "" } },
                  [
                    _c("v-toolbar-title", { staticClass: "pr-2" }, [
                      _vm._v("Webhooks"),
                    ]),
                    _c(
                      "v-btn",
                      {
                        attrs: { "x-small": "", color: "secondary" },
                        on: { click: _vm.openNewWebhookModal },
                      },
                      [_vm._v("Add")]
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "item.name",
            fn: function ({ item }) {
              return [
                _vm._v(" " + _vm._s(_vm._f("capitalize")(item.name)) + " "),
              ]
            },
          },
          {
            key: "item.required",
            fn: function ({ item }) {
              return [
                item.url
                  ? _c("v-icon", { attrs: { color: "#23b912" } }, [
                      _vm._v("$check"),
                    ])
                  : _c("v-icon", { attrs: { color: "#FF0000" } }, [
                      _vm._v("$times"),
                    ]),
              ]
            },
          },
          {
            key: "item.actions",
            fn: function ({ item }) {
              return [
                _c(
                  "v-tooltip",
                  {
                    attrs: { bottom: "" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "activator",
                          fn: function ({ on, attrs }) {
                            return [
                              _c(
                                "v-icon",
                                _vm._g(
                                  _vm._b(
                                    {
                                      staticClass: "mr-2",
                                      attrs: { small: "" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.editWebhook(item)
                                        },
                                      },
                                    },
                                    "v-icon",
                                    attrs,
                                    false
                                  ),
                                  on
                                ),
                                [_vm._v(" mdi-pencil ")]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      true
                    ),
                  },
                  [_c("span", [_vm._v("Edit Webhook")])]
                ),
                _c(
                  "v-tooltip",
                  {
                    attrs: { bottom: "" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "activator",
                          fn: function ({ on, attrs }) {
                            return [
                              _c(
                                "v-icon",
                                _vm._g(
                                  _vm._b(
                                    {
                                      attrs: { small: "" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.promptForDelete(item)
                                        },
                                      },
                                    },
                                    "v-icon",
                                    attrs,
                                    false
                                  ),
                                  on
                                ),
                                [_vm._v(" mdi-delete ")]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      true
                    ),
                  },
                  [_c("span", [_vm._v("Delete Webhook")])]
                ),
              ]
            },
          },
        ]),
      }),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "600" },
          on: {
            keydown: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
              )
                return null
              _vm.showDeleteModal = false
            },
          },
          model: {
            value: _vm.showDeleteModal,
            callback: function ($$v) {
              _vm.showDeleteModal = $$v
            },
            expression: "showDeleteModal",
          },
        },
        [
          _vm.webhookToDelete
            ? _c(
                "v-card",
                { staticClass: "p-3", attrs: { justify: "center" } },
                [
                  _c("v-card-title", [
                    _vm._v(
                      " Are you sure you want to delete webhook " +
                        _vm._s(` ${_vm.webhookToDelete.name}`) +
                        "? "
                    ),
                  ]),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { small: "", color: "secondary" },
                          on: {
                            click: function ($event) {
                              _vm.showDeleteModal = false
                            },
                          },
                        },
                        [_vm._v("No ")]
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: { small: "", color: "secondary" },
                          on: { click: _vm.deleteWebhook },
                        },
                        [_vm._v("Yes ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c("LoadingModal", {
        attrs: { loadingText: _vm.loadingText, showModal: _vm.isSubmitting },
      }),
      _c("WebhookNew", {
        ref: "newModal",
        attrs: { hpps: _vm.hpps },
        on: { created: _vm.getData },
      }),
      _c("WebhookEdit", {
        ref: "editModal",
        attrs: { webhookToEdit: _vm.webhookToEdit, hpps: _vm.hpps },
        on: { updated: _vm.getData },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }