var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.value
    ? _c(
        "div",
        {
          staticClass: "alert-box",
          class: {
            "alert-info": _vm.alertType === _vm.AlertType.Info,
            "alert-warning": _vm.alertType === _vm.AlertType.Warning,
            "alert-error": _vm.alertType === _vm.AlertType.Error,
            "alert-success": _vm.alertType === _vm.AlertType.Success,
          },
          attrs: { role: "alert" },
        },
        [
          _c("p", { staticClass: "tw-font-bold" }, [
            _vm._v(_vm._s(_vm.headerText)),
          ]),
          _c("p", [_vm._v(_vm._s(_vm.message))]),
          _vm.showToggle
            ? _c(
                "span",
                {
                  staticClass: "alert-box-toggle",
                  on: { click: _vm.toggleVisbility },
                },
                [
                  _c(
                    "svg",
                    {
                      staticClass: "alert-box-close",
                      attrs: {
                        role: "button",
                        xmlns: "http://www.w3.org/2000/svg",
                        viewBox: "0 0 20 20",
                      },
                    },
                    [
                      _c("title", [_vm._v("Close")]),
                      _c("path", {
                        attrs: {
                          d: "M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z",
                        },
                      }),
                    ]
                  ),
                ]
              )
            : _vm._e(),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }