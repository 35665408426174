var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-dialog",
    {
      ref: "loadingModal",
      attrs: { persistent: "", width: "400" },
      model: {
        value: _vm.showModal,
        callback: function ($$v) {
          _vm.showModal = $$v
        },
        expression: "showModal",
      },
    },
    [
      _c(
        "v-card",
        { attrs: { color: "secondary", dark: "" } },
        [
          _c(
            "v-card-text",
            [
              _vm._v(" " + _vm._s(_vm.loadingText) + " "),
              _c("v-progress-linear", {
                staticClass: "mb-0",
                attrs: { indeterminate: "", color: "white" },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }