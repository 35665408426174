var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-row",
    { attrs: { justify: "center" } },
    [
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "600" },
          model: {
            value: _vm.idleModal,
            callback: function ($$v) {
              _vm.idleModal = $$v
            },
            expression: "idleModal",
          },
        },
        [
          _c(
            "v-card",
            { staticClass: "p-3", attrs: { justify: "center" } },
            [
              _c("v-card-title", { staticClass: "justify-center" }, [
                _c("h1", { staticClass: "red--text" }, [
                  _vm._v("Session Expiring Soon"),
                ]),
                _c("strong", [
                  _c("p", [
                    _vm._v(" You will be automatically logged out in "),
                    _c("br"),
                    _vm._v(
                      " " +
                        _vm._s(_vm.time / 1000) +
                        " seconds due to inactivity."
                    ),
                  ]),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }