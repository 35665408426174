var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.currentUserCanManageSettings
    ? _c(
        "div",
        { staticClass: "tw-px-2" },
        [
          _c(
            "v-container",
            { attrs: { fluid: "" } },
            [
              _vm.context === "merchant"
                ? _c(
                    "v-row",
                    [_c("v-col", [_c("h2", [_vm._v("Settings")])])],
                    1
                  )
                : _vm._e(),
              _vm._l(_vm.settingRows, function (row, rowIndex) {
                return _c(
                  "v-row",
                  { key: rowIndex },
                  _vm._l(row.columns, function (group, colIndex) {
                    return _c(
                      "v-col",
                      { key: colIndex, attrs: { cols: "4" } },
                      [
                        _c(
                          "v-card",
                          {
                            staticClass: "mx-auto",
                            attrs: {
                              "max-width": "400",
                              outlined: "",
                              elevation: "2",
                            },
                          },
                          [
                            _c(
                              "v-card-title",
                              { staticClass: "justify-center" },
                              [_vm._v(" " + _vm._s(group.title) + " ")]
                            ),
                            _c(
                              "v-list",
                              { staticClass: "pl-4" },
                              _vm._l(group.settings, function (child, index) {
                                return _c(
                                  "v-list-item",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.showLink(child),
                                        expression: "showLink(child)",
                                      },
                                    ],
                                    key: index,
                                    attrs: { to: { name: child.route } },
                                  },
                                  [
                                    _vm.showLink(child)
                                      ? _c(
                                          "v-list-item-icon",
                                          [
                                            _c(
                                              "v-icon",
                                              { attrs: { size: "25" } },
                                              [_vm._v(_vm._s(child.icon))]
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm.showLink(child)
                                      ? _c("v-list-item-title", {
                                          domProps: {
                                            textContent: _vm._s(child.title),
                                          },
                                        })
                                      : _vm._e(),
                                    child.help
                                      ? _c(
                                          "v-tooltip",
                                          {
                                            attrs: { top: "", maxWidth: "300" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "activator",
                                                  fn: function ({ on, attrs }) {
                                                    return [
                                                      _c(
                                                        "v-icon",
                                                        _vm._g(
                                                          _vm._b(
                                                            {
                                                              attrs: {
                                                                color:
                                                                  "primary",
                                                                dark: "",
                                                              },
                                                            },
                                                            "v-icon",
                                                            attrs,
                                                            false
                                                          ),
                                                          on
                                                        ),
                                                        [
                                                          _vm._v(
                                                            " mdi-help-circle-outline "
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(_vm._s(child.help)),
                                            ]),
                                          ]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                )
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  }),
                  1
                )
              }),
            ],
            2
          ),
        ],
        1
      )
    : _c("Alert", {
        attrs: {
          alertType: _vm.AlertType.Error,
          header: "Access Denied",
          message: "You don't have sufficient permissions to manage Settings.",
          showToggle: false,
        },
        model: {
          value: _vm.showAlert,
          callback: function ($$v) {
            _vm.showAlert = $$v
          },
          expression: "showAlert",
        },
      })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }