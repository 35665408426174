var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "spinner", class: { fullscreen: _vm.fullscreen } },
    [
      _c("v-progress-circular", {
        staticClass: "mb-0",
        attrs: {
          indeterminate: "",
          color: "primary",
          size: "100",
          width: "10",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }