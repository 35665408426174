var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-navigation-drawer",
    {
      key: _vm.isPinned,
      attrs: {
        app: "",
        permanent: "",
        clipped: "",
        "expand-on-hover": !_vm.isPinned,
        light: "",
        "mini-variant": _vm.mini,
      },
      on: {
        "update:miniVariant": function ($event) {
          _vm.mini = $event
        },
        "update:mini-variant": function ($event) {
          _vm.mini = $event
        },
      },
      model: {
        value: _vm.drawer,
        callback: function ($$v) {
          _vm.drawer = $$v
        },
        expression: "drawer",
      },
    },
    [
      _c(
        "v-list",
        { attrs: { nav: "", dense: "" } },
        _vm._l(_vm.allowedItems, function (item, index) {
          return _c(
            "div",
            { key: index },
            [
              _vm.navItemHasChildren(item)
                ? _c(
                    "v-list-group",
                    {
                      attrs: {
                        color: "black",
                        "prepend-icon": item.icon,
                        "no-action": "",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function () {
                              return [
                                _c("v-list-item-title", [
                                  _vm._v(_vm._s(item.text)),
                                ]),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        true
                      ),
                    },
                    _vm._l(item.children, function (child, childIdx) {
                      return _c(
                        "v-list-item",
                        {
                          key: childIdx,
                          attrs: { to: { name: child.route } },
                          nativeOn: {
                            mousedown: function ($event) {
                              return _vm.mousedown(child)
                            },
                          },
                        },
                        [_c("v-list-item-title", [_vm._v(_vm._s(child.text))])],
                        1
                      )
                    }),
                    1
                  )
                : [
                    item.route
                      ? _c(
                          "v-list-item",
                          {
                            attrs: { to: { name: item.route } },
                            nativeOn: {
                              mousedown: function ($event) {
                                return _vm.mousedown(item)
                              },
                            },
                          },
                          [
                            _c(
                              "v-list-item-icon",
                              [
                                _c("v-icon", { attrs: { size: "25" } }, [
                                  _vm._v(_vm._s(item.icon)),
                                ]),
                              ],
                              1
                            ),
                            _c("v-list-item-title", {
                              domProps: { textContent: _vm._s(item.text) },
                            }),
                            index === 0
                              ? _c(
                                  "v-btn",
                                  {
                                    attrs: {
                                      right: "",
                                      icon: "",
                                      color: "secondary",
                                      "x-small": "",
                                    },
                                  },
                                  [
                                    _c(
                                      "v-icon",
                                      {
                                        attrs: { color: "primary" },
                                        on: {
                                          click: function ($event) {
                                            $event.preventDefault()
                                            return _vm.togglePinned()
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.isPinned
                                                ? "$thumbtack-pinned"
                                                : "$thumbtack-off"
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        )
                      : _c(
                          "v-list-item",
                          { attrs: { href: item.href, target: "_blank" } },
                          [
                            _c(
                              "v-list-item-icon",
                              [
                                _c("v-icon", { attrs: { size: "25" } }, [
                                  _vm._v(_vm._s(item.icon)),
                                ]),
                              ],
                              1
                            ),
                            _c("v-list-item-title", {
                              domProps: { textContent: _vm._s(item.text) },
                            }),
                          ],
                          1
                        ),
                  ],
            ],
            2
          )
        }),
        0
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }