var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-footer",
    {
      staticClass: "mt-4",
      attrs: { inset: "", padless: "", width: "auto", color: "transparent" },
    },
    [
      _c("v-col", { staticClass: "text-center" }, [
        _vm._v(" © "),
        _c("strong", [_vm._v(_vm._s(_vm.company))]),
        _vm._v(" " + _vm._s(new Date().getFullYear()) + " "),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }