var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-app-bar",
    { attrs: { app: "", "clipped-left": "", color: "primary" } },
    [
      _c(
        "router-link",
        { attrs: { to: "/" } },
        [
          _c("v-img", {
            staticClass: "mx-2",
            attrs: {
              "max-height": "40",
              "max-width": "100",
              contain: "",
              src: this.logo,
            },
          }),
        ],
        1
      ),
      _c("v-toolbar-title", { staticClass: "tw-text-brand-primary-text" }, [
        _vm._v(_vm._s(_vm.brandCompany)),
      ]),
      _c("v-spacer"),
      _c(
        "v-menu",
        {
          attrs: { "offset-y": "" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function ({ on, attrs }) {
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      _vm._b(
                        { attrs: { color: "accent", small: "" } },
                        "v-btn",
                        attrs,
                        false
                      ),
                      on
                    ),
                    [
                      _vm._v(_vm._s(_vm.currentUserDisplayName) + " "),
                      _c("v-icon", { attrs: { right: "" } }, [
                        _vm._v("$chevron-down"),
                      ]),
                    ],
                    1
                  ),
                ]
              },
            },
          ]),
        },
        [
          _c(
            "v-list",
            [
              _vm._l(_vm.menuItems, function (item, index) {
                return _c(
                  "v-list-item",
                  { key: index, attrs: { link: "", to: { name: item.to } } },
                  [
                    _c("v-list-item-title", [_vm._v(_vm._s(item.title))]),
                    _c(
                      "v-list-item-icon",
                      [_c("v-icon", [_vm._v(_vm._s(item.icon))])],
                      1
                    ),
                  ],
                  1
                )
              }),
              _vm.context !== "admin"
                ? _c(
                    "v-list-item",
                    { attrs: { link: "", to: { path: "/helpful-videos" } } },
                    [
                      _c("v-list-item-title", [_vm._v("Helpful Videos")]),
                      _c(
                        "v-list-item-icon",
                        [_c("v-icon", [_vm._v("mdi-video")])],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-list-item",
                { on: { click: _vm.logout } },
                [
                  _c("v-list-item-title", [_vm._v("Logout")]),
                  _c(
                    "v-list-item-icon",
                    [
                      _c("v-icon", {
                        domProps: { textContent: _vm._s("$sign-out-alt") },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }