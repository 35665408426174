var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.shown
    ? _c("div", [
        _vm.curtain ? _c("div", { staticClass: "curtain" }) : _vm._e(),
        _c(
          "div",
          {
            staticClass:
              "tw-z-50 tw-h-10 tw-flex tw-fixed tw-left-0 tw-top-0 tw-w-full tw-bg-black",
          },
          [
            _vm.showBack
              ? _c(
                  "v-btn",
                  {
                    staticClass: "tw-bg-black",
                    attrs: { color: "secondary" },
                    on: { click: _vm.hide },
                  },
                  [_c("Icon", { attrs: { icon: "arrow-left" } })],
                  1
                )
              : _vm._e(),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "doc-viewer" },
          [
            _vm.supportPage
              ? _c(
                  "v-row",
                  { attrs: { justify: "end" } },
                  [
                    _c(
                      "v-btn",
                      {
                        attrs: { small: "", color: "red" },
                        on: {
                          click: function ($event) {
                            return _vm.$emit("closeRatesModal")
                          },
                        },
                      },
                      [_vm._v("X")]
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "div",
              { staticClass: "body" },
              [
                _vm.showHtml
                  ? _c("div", { domProps: { innerHTML: _vm._s(_vm.html) } })
                  : _vm._t("body"),
              ],
              2
            ),
            _c(
              "div",
              {
                staticClass:
                  "tw-flex tw-justify-center tw-sticky tw-left-0 tw-bottom-0 tw-w-full tw-bg-gray-200",
              },
              [_vm._t("footer")],
              2
            ),
          ],
          1
        ),
        _c("div", { staticClass: "status-bar" }, [_vm._t("status")], 2),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }