var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-row",
    { attrs: { justify: "center" } },
    [
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "700" },
          on: {
            keydown: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
              )
                return null
              return _vm.$emit("closeDeleteModal")
            },
          },
          model: {
            value: _vm.deleteModal,
            callback: function ($$v) {
              _vm.deleteModal = $$v
            },
            expression: "deleteModal",
          },
        },
        [
          _c(
            "v-card",
            { staticClass: "p-3", attrs: { justify: "center" } },
            [
              _c("v-card-title", [
                _vm._v(
                  " Are you sure you want to " +
                    _vm._s(_vm.modalAction) +
                    " " +
                    _vm._s(
                      _vm.config.entityName
                        ? _vm.config.entityName
                        : "subscription"
                    ) +
                    ": "
                ),
                _c("strong", [
                  _vm._v(" " + _vm._s(_vm.identifierProperty) + " "),
                ]),
                _vm._v("? "),
              ]),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { small: "", color: "secondary" },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("closeDeleteModal")
                        },
                      },
                    },
                    [_vm._v("No ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { small: "", color: "secondary" },
                      on: {
                        click: function ($event) {
                          return _vm.$emit(
                            "deleteEntity",
                            _vm.eventToBeTriggered
                          )
                        },
                      },
                    },
                    [_vm._v("Yes ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }