var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "input-wrapper",
      class: _vm.disabled ? "tw-opacity-25" : "",
    },
    [
      _vm.hasLabel
        ? _c("label", { attrs: { for: _vm.name } }, [_vm._v(_vm._s(_vm.label))])
        : _vm._e(),
      _c("textarea", {
        style: _vm.getStyle,
        attrs: {
          id: _vm.inputId,
          name: _vm.name,
          autofocus: _vm.autofocus,
          disabled: _vm.disabled,
          readonly: _vm.readonly,
          rows: _vm.rows,
        },
        domProps: { value: _vm.value },
        on: {
          input: function ($event) {
            return _vm.$emit("input", $event.target.value)
          },
        },
      }),
      _c(
        "div",
        { staticClass: "tw-text-xs tw-text-gray-600" },
        [_vm._t("footer")],
        2
      ),
      _vm.errors && _vm.validationKey in _vm.errors
        ? _c(
            "ul",
            { staticClass: "errors" },
            _vm._l(_vm.errors[_vm.validationKey], function (error, i) {
              return _c("li", { key: i }, [_vm._v(_vm._s(error))])
            }),
            0
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }