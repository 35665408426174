var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.shown
    ? _c("div", [
        _vm.curtain
          ? _c("div", {
              staticClass: "curtain",
              on: { click: _vm.curtainClicked },
            })
          : _vm._e(),
        _c("div", { staticClass: "modal" }, [
          _vm.showCloseButton
            ? _c(
                "a",
                {
                  staticClass: "close-button",
                  attrs: { href: "" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.hide.apply(null, arguments)
                    },
                  },
                },
                [_vm._v("×")]
              )
            : _vm._e(),
          _c("div", { staticClass: "body" }, [_vm._t("default")], 2),
          _vm.hasButtons
            ? _c("div", { staticClass: "buttons" }, [_vm._t("buttons")], 2)
            : _vm._e(),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }